<template>
  <BreadCrumb :breadcrumbs="breadcrumbs" />
  <Modal v-show="showModal" @closeModal="showModal = false" @confirmModal="confirmModal"/>
  <Alert :class="alertClass" v-show="alertContent" @closeAlert="alertContent = ''">{{alertContent}}</Alert>
  <div style="margin-bottom: 4rem!important;">
    <div class="input-group w-50 float-start">
      <span class="input-group-text fa fa-search"/>
      <input type="text" class="form-control" placeholder="Search by Credit Card" v-model="term" @input="filterList">
      <span class="input-group-text fa fa-trash" @click="term=''; filterList();" style="cursor: pointer;"></span>
    </div>
    <select v-model="status" class="form-select float-start ms-3" style="width: 150px;" @change="getList">
      <option v-for="(item, index) in statusList" :key="index" :value="item">{{item}}</option>
    </select>
    <select v-model="type" class="form-select float-start ms-3" style="width: 150px;" @change="getList">
      <option v-for="(item, index) in typeList" :key="index" :value="item">{{item}}</option>
    </select>
    <router-link class="btn btn-primary float-end" style="width: 86px;" to="/creditcard/bill/new">New Bill</router-link>
  </div>
  <div class="clearfix"></div>
  <div class="mb-2 ms-2">
    <span class="me-4">Uncovered Bill: {{ totalUncovered.toFixed(2) }}</span>
    <span class="me-4">Billed: {{ totalBilled.toFixed(2) }}</span>
    <span class="me-4">Scheduled: {{ totalScheduled.toFixed(2) }}</span>
    <span class="me-4">Requested: {{ totalRequested.toFixed(2) }}</span>
  </div>
  <Table :tableBody="filteredTableBody" :tableHeader="tableHeader"></Table>
</template>

<script>
import axios from "axios"
import { decode } from "../util.js"

function getStatus(item, itemList) {
  if (item.type === "Bill") {
    var paymentList = itemList.filter((entry) => { return entry.type === "Bill Payment" && entry.creditCard === item.creditCard && new Date(entry.dueDate) < new Date(item.dueDate); });
    var payment = paymentList && paymentList.length > 0 ? paymentList.reduce((curr, entry) => { curr += entry.amount; return curr; }, 0) : 0;
    return { type: "status", class: "badge bg-" + (payment - item.amount > -0.0001 || item.status == "Paid" ? "success" : "danger"), name: item.status };
  } else {
    var normal = item.status === "Paid" || new Date(item.dueDate) > new Date(new Date().setDate(new Date().getDate()-2));
    return { type: "status", class: "badge bg-" + (normal ? "success" : "danger"), name: item.status };
  }
}

function getAction(item, openModal) {
    var result = [];
    if (item.status === "Not Paid") {
      result.push({ type: "button", click: function() { openModal(item.id, "confirm") }, name: "<span class='fa fa-power-off'></span>", class: "btn-success" })
    }
    result.push({ type: "link", link: "/creditcard/bill/edit?id=" + item.id, class: "btn btn-primary btn-sm fa fa-pencil" });
    result.push({ type: "link", link: "/creditcard/bill/edit?action=COPY&id=" + item.id, class: "btn btn-info btn-sm fa fa-copy" });
    if (item.status === "Not Paid") {
      result.push({ type: "button", click: function() { openModal(item.id, "delete") }, name: "<span class='fa fa-trash'></span>", class: "btn-danger" });
    }
    return result;
}

export default {
  name: "BestBuy",
  mounted() {
    this.getList();
  },
  data() {
    return {
      tableHeader: [
        "Name",
        "Credit Card",
        "Amount",
        "Due Date",
        "Request Date",
        "Status",
        "Type",
        "Source",
        "Action"
      ],
      tableBody: [],
      filteredTableBody: [],
      creditCardList: [],
      breadcrumbs: [{ name: "Credit Card", link: "/creditcard" }, { name: "Bill" }],
      alertContent: "",
      alertClass: "alert-danger",
      statusList: ["All", "Not Paid", "Paid"],
      typeList: ["All", "Bill", "Bill Payment"],
      status: "Not Paid",
      type: "All",
      total: 0,
      failed: 0,
      loading: false,
      term: this.$route.query.creditcard || "",
      showModal: false,
      totalUncovered: 0,
      totalBilled: 0,
      totalScheduled: 0,
      totalRequested: 0,
      currentId: "",
      opt: ""
    };
  },
  methods: {
    openModal(id, opt) {
      this.showModal = true;
      this.currentId = id;
      this.opt = opt;
    },
    confirmModal() {
      if (this.opt === "delete") {
        axios.delete("/creditcard/bill/" + this.currentId).then(() => {
          this.getList();
        }).catch(error => {
          this.alertContent = error.response ? error.response.data : error;
          this.alertClass = "alert-danger";
        })
      } else if (this.opt === "confirm") {
        axios.put("/creditcard/bill/" + this.currentId).then(() => {
          this.getList();
        }).catch(error => {
          this.alertContent = error.response ? error.response.data : error;
          this.alertClass = "alert-danger";
        })
      }
      this.showModal = false;
    },
    getList() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      axios.get("/creditcard").then(response => {
        this.creditCardList = response.data.map(item => { 
          return {
            name: item.name,
            creditcard: decode(item.creditcard)
        }});
        axios
        .get("/creditcard/bill?status=" + this.status + "&type=" + this.type)
        .then((response) => {
          var openModal = this.openModal;
          this.tableBody = response.data.map((item) => {
            return {
              name: this.creditCardList.filter(obj => obj.creditcard === decode(item.creditCard))[0] ? this.creditCardList.filter(obj => obj.creditcard === decode(item.creditCard))[0].name : "",
              creditCard: {
                type: "buttonLink",
                click: () => { this.term = decode(item.creditCard); this.filterList() },
                name: decode(item.creditCard)
              },
              amount: item.amount,
              dueDate: item.dueDate,
              requestDate: item.type === "Bill Payment" ? item.requestDate : "",
              status: getStatus(item, response.data),
              type: item.type,
              source: item.source,
              action: getAction(item, openModal)
            };
          });

          this.filterList();
          this.loading = false;
        })
        .catch((error) => {
          this.alertContent = error.response ? error.response.data : error;
          this.alertClass = "alert-danger";
          this.loading = false;
        });
      });
    },
    filterList() {
      if (this.term) {
        this.filteredTableBody = this.tableBody.filter((item) => item.creditCard.name.toLowerCase().indexOf(this.term.toLowerCase()) > -1);
      } else {
        this.filteredTableBody = this.tableBody;
      }
      if (this.filteredTableBody && this.filteredTableBody.length > 0) {
        this.totalUncovered = this.filteredTableBody.reduce((curr, entry) => { if(entry.type === "Bill" && entry.status.class.indexOf("danger") > 0) return curr += entry.amount; else return curr; }, 0);
        this.totalBilled = this.filteredTableBody.reduce((curr, entry) => { if(entry.type === "Bill") return curr += entry.amount; else return curr; }, 0);
        this.totalRequested = this.filteredTableBody.reduce((curr, entry) => { if(entry.type === "Bill Payment") return curr += entry.amount; else return curr; }, 0);
        this.totalScheduled = this.filteredTableBody.reduce((curr, entry) => { if(entry.type === "Bill Payment" && entry.dueDate) return curr += entry.amount; else return curr; }, 0);
      } else {
        this.totalUncovered = 0;
        this.totalBilled = 0;
        this.totalRequested = 0;
        this.totalScheduled = 0;
      }
    }
  },
};
</script>